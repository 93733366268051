@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
:root {
  /* Design system colors */
  --tone-200: rgba(5, 5, 5, 0.06);
  --tone-300: rgba(0, 0, 0, 0.11);
  --tone-400: rgba(0, 0, 0, 0.28);
  --tone-500: rgba(0, 0, 0, 0.51);
  --tone-600: rgba(0, 0, 0, 0.65);
  --tone-700: rgba(0, 0, 0, 0.76);
  --tone-800: rgba(0, 0, 0, 0.86);
  --tone-900: rgba(0, 0, 0, 0.98);

  --absolute-100: #ffffff;
  --absolute-200: #f0f0f0;
  --absolute-300: #e3e3e3;
  --absolute-700: #3d3d3d;
  --absolute-800: #1f1f1f;
  --absolute-900: #050505;

  --aquamarine-500: rgba(1, 135, 144, 1);

  --red-100: rgba(228, 70, 86, 0.1);
  --red-500: rgba(211, 53, 69, 1);

  --blue-100: rgba(0, 122, 255, 0.1);
  --blue-400: rgba(0, 122, 255, 1);

  --text-secondary: rgba(0, 0, 0, 0.54);
  --text-disabled: rgba(0, 0, 0, 0.38);

  --white: white;
  --red: #ff3b2f;
  --light-red: #e44656;
  --transparent-red: rgba(228, 70, 86, 0.1);
  --light-footer-gray: #f1f1f1;
  --light-footer-bg-gray: #f8f8f8;
  --light-text-gray: rgba(0, 0, 0, 0.51);
  --text-black: rgba(0, 0, 0, 0.98);
  --text-black-title-popup: rgba(46, 46, 45, 1);
  --background-light-secondary: #f2f2f7;
  --popup-background: rgba(0, 0, 0, 0.28);
  --gray-border: rgba(0, 0, 0, 0.11);
  --gray-border-light: rgba(194, 194, 194, 0.5);
  --dark-gray: #333333;
  --light-gray: #d9d9d9;
  --medium-gray: #979797;
  --medium-dark-gray: #646464;
  --disabled-gray: #ebebeb;
  --disabled-dark-gray: #c2c2c2;
  --placeholder-gray: #f1f1f1;
  --white: white;
  --black: black;
  --light-black: rgba(61, 61, 61, 1);
  --main: #5648f8;
  --blue: rgba(0, 122, 255, 1);
  --link-blue: rgba(0, 106, 219, 1);
  --medium-main: #c1bbff;
  --light-main: #e7eafb;
  --accent: #22ccd8;
  --light-cookie-accent: rgba(233, 250, 251, 1);
  --light-accent: rgba(34, 204, 216, 0.2);
  --light-background-accent: rgba(144, 247, 254, 0.08);
  --lightest-accent: #b3eef2;
  --mts-red: #e30611;
  --yelow: #ff9f0a;
  --aquamarine: rgba(34, 204, 216, 0.1);

  /* New colors */
  --black-bg: var(--absolute-700);
  --black-hover: var(--absolute-800);

  /* Border-radius variants */
  --radius-sx: 4px;
  --radius-s: 8px;
  --radius-m: 16px;
  --radius-l: 24px;
  --radius-xl: 32px;

  /* Box-shadows */
  --shadow-xl: 0px 25px 59px rgba(0, 0, 0, 0.2);
  --shadow-m: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);

  /**
   * Fonts using while preloading Lato
   * Or if preloading failed
   */
  --fonts: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;

  --font-family-main: Roboto;

  --font-size-heading-first: 30px;
  --font-size-heading-second: 22px;
  --font-size-heading-third: 18px;

  /**
   * Margin for the product card
   * Is using in the product grids and sliders
   */
  --product-margin: 8px;

  /**
   * Height of the `Header` component
   */
  --header-height: 84px;

  /**
   * Margins between content and page body
   * On desktop does not make sense because numeric value is unknown
   * 0 here on desktop to not to violate calculations
   */
  --wrapper-margin: 0;
  --wrapper-padding: 120px;

  /**
   * Cards spacings and width
   */

  --card-spacing: 16px;

  --card-width: 282px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
:root {
    --product-margin: 7.5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --product-margin: 5px
}
  }
@media only screen and (max-width: 767px) {
:root {
    --header-height: 54px
}
  }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
:root {
    --wrapper-margin: 15px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-spacing: 24px
}
  }
@media only screen and (min-width: 992px) {
:root {
    --card-width: calc((100% - (var(--card-spacing) * 2)) / 3)
}
  }
/* TODO: complete */
/* 1600 */
/* 1300 */
/* 992 */
/* 992 */
/* 768 */
/* 320 */
.Main {
    will-change: transform;
    contain: content;
    content-visibility: auto;
    contain-intrinsic-size: 1px 5000px;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-perspective: 1000px;
            perspective: 1000px;
  }
.Main .ProductCard,
    .Main .Benefits,
    .Main .CustomerReviews,
    .Main .ProductsSlider,
    .Main .Cities,
    .Main .ProductsTabs {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      will-change: transform;
      contain: layout style paint;
      content-visibility: auto;
      contain-intrinsic-size: 1px 300px;
    }
.Main img {
      content-visibility: auto;
      contain: layout style paint;
      will-change: transform;
      loading: lazy;
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
    }
.Main .ProductsSlider,
    .Main .Cities,
    .Main .ProductsTabs {
      contain: content;
      content-visibility: auto;
      contain-intrinsic-size: 1px 500px;
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
    }
.Main__recently {
      margin-bottom: 48px;
      contain-intrinsic-size: 1px 400px;
    }
@media only screen and (max-width: 767px) {
.Main__recently {
        margin-bottom: 24px
    }
      }
.Main__predictedOffer {
      margin-bottom: 48px;
      contain-intrinsic-size: 1px 400px;
    }
@media only screen and (max-width: 767px) {
.Main__predictedOffer {
        margin-bottom: 24px
    }
      }
.Main__popular {
      margin-bottom: 48px;
      contain-intrinsic-size: 1px 800px;
    }
@media only screen and (max-width: 767px) {
.Main__popular {
        margin-bottom: 24px
    }
      }
.Main__cities {
      margin-bottom: 48px;
      contain-intrinsic-size: 1px 300px;
    }
@media only screen and (max-width: 767px) {
.Main__cities {
        margin-bottom: 24px
    }
      }
.Main__destinations {
      margin-bottom: 48px;
      contain-intrinsic-size: 1px 200px;
    }
@media only screen and (max-width: 767px) {
.Main__destinations {
        margin-bottom: 24px
    }
      }
.Main .Button,
    .Main .Link {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      will-change: transform;
    }
.Main h1,
    .Main h2 {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      will-change: transform;
    }
.Main__cities .Cities {
      margin: 14px 0 0 0;
    }
@media only screen and (min-width: 768px) and (max-width: 991px) {
.Main__cities .Cities {
        margin: 18px 0 0 0
    }
      }
@media only screen and (max-width: 767px) {
.Main__cities .Cities {
        margin: 18px 0 8px
    }
      }
.Main__more {
      margin-top: 10px;
      margin-bottom: 5px;
    }
@media only screen and (min-width: 768px) and (max-width: 991px),only screen and (max-width: 767px) {
.Main__more {
        margin-bottom: 0px
    }
      }
@media only screen and (max-width: 767px) {
.Main__more {
        margin-top: 4.5px
    }
      }
.Main__destinations {
      font-family: Roboto;
      font-family: var(--font-family-main);
    }
.Main__destinations .ProductsTabs__select {
          margin-bottom: 15px;
        }
@media only screen and (max-width: 767px) {
.Main__destinations .ProductsTabs__select {
            margin-bottom: 5px
        }
          }
.Main__destinations .ProductsTabs__container {
          width: calc(100% + 10px);
          margin: 0 -5px;
          margin-top: 5px;
        }
.Main__destinations .Link {
        width: auto;
        margin: 5px;
      }
.Main__attractions .BrainhubCarouselItem > a {
        width: auto;
      }
@media only screen and (min-width: 768px) and (max-width: 991px) {
.Main__attractions .AttractionCard {
          width: 160px;
          height: 220px
      }
        }
@media only screen and (max-width: 767px) {
.Main__attractions .AttractionCard {
          width: 135px;
          height: 185px
      }
        }
.Banner {
    margin-top: 65px;
  }
@media only screen and (max-width: 767px) {
.Banner {
      margin-top: 45px
  }
    }
.ProductsSlider--withArrows {
    margin: 0 -60px;
  }
.ProductsSlider--withArrows.ProductsSlider--flat .ProductsSlider__arrow {
      display: none;
    }
